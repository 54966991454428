import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { fetchNotifications } from './notifications'
import { synchroniseSession } from '../../services/sessions'

export const customerSlice = createSlice({
    name: 'customer',
    initialState: {
        currentUser: null,
        droits: {},
        userFetched: false,
        canShowCdba: false,
        vrpAsCustomer: null,
    },
    reducers: {
        setCurrentUser: (state, action) => {
            state.userFetched = true
            state.currentUser = action.payload
            state.droits = action.payload.droits?.reduce((carry, droit) => ({ ...carry, [droit.slug]: true }), {}) ?? {}
        },
        setDemat: (state, action) => {
            state.currentUser.bpcustomer.demat = action.payload
        },
        setUserFetched: state => {
            state.userFetched = true
        },
        setVrpAsCustomer: (state, action) => {
            state.vrpAsCustomer = action.payload
        },
        setCanShowCdba: (state, action) => {
            state.canShowCdba = action.payload
        },
    },
})

export const { setCurrentUser, setUserFetched, setNeedChangePassword, setVrpAsCustomer, setDemat, setCanShowCdba } =
    customerSlice.actions

export const setCurrentCustomer = customer => dispatch => {
    dispatch(setCurrentUser(customer))
    dispatch(fetchNotifications())

    if (customer.droits.find(droit => droit.slug === 'cdba') !== undefined) {
        axios
            .get('/api/cdba/search?size=1')
            .then(response => {
                dispatch(setCanShowCdba(!!response.data.total))
            })
            .catch(() => {})
    }
}

export const fetchCurrentUser = () => dispatch => {
    return axios
        .get('/api/customer')
        .then(response => {
            axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data.token
            dispatch(setCurrentCustomer(response.data.data))
            dispatch(setVrpAsCustomer(response.data.vrpCustomer ?? null))
        })
        .catch(() => dispatch(setVrpAsCustomer(null)))
        .finally(() => dispatch(setUserFetched()))
}

export const logCustomer = (username, password, bpcnum) => dispatch => {
    return axios
        .post('/api/customer/login', { username, password, bpcnum })
        .then(response => {
            axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data.token
            dispatch(setCurrentCustomer(response.data.data))
            dispatch(setVrpAsCustomer(null))
            synchroniseSession('front')
            return response
        })
        .finally(() => dispatch(setUserFetched()))
}

export const logOutCustomer = () => {
    return axios.get('/api/customer/logout').then(() => {
        synchroniseSession('front')
        window.location.reload()
    })
}

export default customerSlice.reducer
