import { localStorageGet, localStorageRemember, localStorageSet } from './localStorage'
import dayjs from 'dayjs'
import { useEffect } from 'react'

let version = {}

export function useSynchronisedSessions(id, delay) {
    version[id] = localStorageRemember('sessionVersion-' + id, dayjs().valueOf())

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (localStorageGet('sessionVersion-' + id) !== version[id]) {
                window.location.reload()
            }
        }, delay)

        return () => clearInterval(intervalId)
    }, [])
}

export const synchroniseSession = id => (version[id] = localStorageSet('sessionVersion-' + id, dayjs().valueOf()))
